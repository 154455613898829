@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "InterBold";
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/static/Inter-Bold.ttf') format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: "InterSemiBold";
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/static/Inter-SemiBold.ttf') format("truetype");
    font-weight: 600;
  }


  @font-face {
    font-family: "InterLight";
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/static/Inter-Light.ttf') format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "InterMedium";
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/static/Inter-Medium.ttf') format("truetype");
    font-weight: 500;
  }

  
  @font-face {
    font-family: "InterRegular";
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/static/Inter-Regular.ttf') format("truetype");
    font-weight: normal;
  }
