/*
////////////////
Input type number Style
///////////////
  */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/*
////////////////
Datepicker Style 
///////////////
  */

html {
  background: var(--theme-bg-opacity) !important;
}

@font-face {
  font-family: "InterMedium";
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/static/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "InterRegular";
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/static/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "InterBold";
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/static/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
}

.datePickerSelect {
  font-size: 16px !important;
  color: #282d46 !important;
}

.react-datepicker-popper {
  transform: unset !important;
  top: 80px !important;
  width: 100% !important;
  z-index: 9999 !important;
}

.react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #d0d5dd;
  padding: 10px !important;
  border-radius: 8px;
  outline: 0;
  font-family: "InterRegular";
}

.react-datepicker__input-container input::placeholder {
  color: #667085;
  font-family: "InterRegular";
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 10px;
  top: 10px;
  width: 25px;
  padding: 0px !important;
  height: auto;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker {
  border: none !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.15) !important;
  font-family: "InterRegular" !important;
  font-size: 12px !important;
  width: 100%;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-radius: 8px 8px 0px 0px !important;
  border-bottom: none !important;
  padding: 8px 3px !important;
}

.react-datepicker__day-name {
  font-size: 12px !important;
  color: #d5d5d6 !important;
  font-family: "InterRegular" !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  max-width: 12% !important;
  width: 100% !important;
}

.react-datepicker__day {
  color: #6a6a6d !important;
}

.react-datepicker__day--selected {
  color: #fff !important;
  background-color: var(--theme) !important;
  border-radius: 8px !important;
}

.react-datepicker__month-container {
  float: unset !important;
}

.react-datepicker__month {
  margin-bottom: 1rem !important;
}

.react-datepicker__day:hover {
  border-radius: 8px !important;
}

.react-datepicker__day--keyboard-selected:hover {
  color: #fff !important;
  background-color: #50b8e7 !important;
  border-radius: 8px !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 8px !important;
}

.react-datepicker__day--outside-month {
  color: #d5d5d6 !important;
}

details[open] summary {
  margin-bottom: 40px;
}

@media (max-width: 1536px) {
  .react-datepicker__input-container input {
    width: 100%;
    border: 1px solid #d0d5dd;
    padding: 7.8px !important;
    border-radius: 8px;
    outline: 0;
    font-family: "InterRegular";
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    top: 7px !important;
  }
}

/*
////////////////
Tree Chart Style 
///////////////
  */

.org-tree-node-children,
.css-19nw3og {
  width: 100%;
}

.css-1rrabo2 {
  padding-top: 50px;
}

.css-1qlo6pv {
  left: calc(50% + 1px) !important;
}

.css-kry47k {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1tx6rxn {
  margin-left: -10px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.react-flow__panel.react-flow__attribution.bottom.right {
  display: none !important;
}

/*
////////////////
Loader Style
///////////////
  */

.loader {
  width: 90px;
  height: 90px;
  border: 1px solid var(--theme);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: borderrgb(158, 148, 148);
  position: absolute;
  left: 50%;
  top: 46px;
  transform: translate(-50%, -50%);
  width: 91px;
  height: 91px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-bottom-color: var(--theme);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--theme);
  border-radius: 8px;
}



/*
////////////////
 Overflow Scroll Style
///////////////

/*

////////////////
Vertical Overflow Scroll Style
///////////////
  */
.fc .fc-scroller::-webkit-scrollbar {
  width: 5px;
}

.fc .fc-scroller::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px;
}

.fc .fc-scroller::-webkit-scrollbar-thumb {
  background: var(--theme);
  border-radius: 8px;
}

.fc .fc-scroller::-webkit-scrollbar-thumb:hover {
  background: var(--theme);
}

.verticalOverflowScrollStyle {
  padding-right: 5px;
  overflow-y: auto;
}

/* width */
.verticalOverflowScrollStyle::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.verticalOverflowScrollStyle::-webkit-scrollbar-track {
  background: #a2a1a81a;
  border-radius: 100px;
}

/* Handle */
.verticalOverflowScrollStyle::-webkit-scrollbar-thumb {
  background: var(--theme);
  border-radius: 100px;
}

/*
////////////////
Horizontal Overflow Scroll Style
///////////////
  */

.horizontalOverflowScrollStyle {
  padding-bottom: 5px;
  overflow-x: auto;
}

/* width */
.horizontalOverflowScrollStyle::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.horizontalOverflowScrollStyle::-webkit-scrollbar-track {
  background: #a2a1a81a;
  border-radius: 100px;
}

/* Handle */
.horizontalOverflowScrollStyle::-webkit-scrollbar-thumb {
  background: var(--theme);
  border-radius: 100px;
}

/*
////////////////
Calendar Style
///////////////
  */
.tabsClass {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.fc {
  border: solid 1px #f2f4f7;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: solid 1px #f2f4f7 !important;
  border-bottom: none !important;
}

.fc-toolbar {
  padding: 12px 15px;
  margin: 0 !important;
}

.fc .fc-button-active {
  background-color: var(--theme) !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
}

.fc .fc-button-primary {
  background-color: #f5f6f7;
  color: #282d46;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-family: "InterMedium";
  text-transform: capitalize;
}

.fc .fc-button-primary:hover {
  background-color: #f5f6f7;
  color: #282d46;
  border: none;
  border-radius: 5px;
}

.fc-prev-button {
  background: none !important;
  padding: 0 !important;
}

.fc-next-button {
  background: none !important;
  padding: 0 !important;
}

.fc-today-button {
  background: none !important;
  color: var(--theme) !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background: transparent !important;
}

.fc-h-event {
  border-left: solid 3px var(--theme);
  background: rgba(80, 184, 231, 0.05);
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  border-radius: 4px 0px 0px 4px !important;
}

.fc-event-title {
  /* color: #282d46; */
  font-family: "InterBold";
  font-size: 11px;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-event-main {
  padding: 6px;
  min-height: 41px !important;
}

/* 
.fc .fc-timegrid-slot {
  height: 5em !important;
} */

.fc-day-today {
  background-color: #ffffff !important;
}

.fc .fc-timegrid-divider {
  padding: 0;
}

.fc-toolbar-title {
  color: #282d46;
  font-size: 12px !important;
  font-family: "InterMedium" !important;
}

.fc-timegrid-axis {
  visibility: hidden;
}

.fc-col-header-cell-cushion {
  color: #282d46;
  font-size: 12px;
  font-family: "InterMedium";
}

.fc-timegrid-slot-label-cushion {
  color: #7d8592 !important;
  font-family: "InterMedium";
  font-size: 10px;
}

.fc-axis {
  position: relative !important;
  left: -30px !important;
}

.fc-event {
  border-width: 3px !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-radius: 4px !important;
}

.fc-daygrid-event-dot {
  display: none !important;
}

.fc-event {
  height: 41px !important;
  max-height: 100%;
}

.fc-event-time {
  display: none !important;
}

.fc-popover-header {
  background: transparent !important;
}

.fc-popover-title {
  font-family: "InterMedium" !important;
  font-size: 13px !important;
}

.fc-popover {
  border: none !important;
  border-radius: 4px !important;
  min-width: 300px !important;
  padding: 10px !important;
}

.fc-popover-body {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 !important;
}

.fc-popover-close {
  width: 44px !important;
  height: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f9fd !important;
  border-radius: 6px;
}

.fc-icon-x::before {
  content: "x";
  font-family: "InterMedium";
  font-size: 20px;
  color: #282d46;
  padding: 5px !important;
}

.fc .fc-daygrid-more-link {
  background-color: var(--theme) !important;
  border-radius: 8px !important;
  color: white !important;
  font-family: "InterMedium" !important;
  width: 30px !important;
  height: 30px;
  margin: 10px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-daygrid-event-harness {
  margin: 5px !important;
}

.fc-timegrid-more-link {
  background-color: var(--theme) !important;
  border-radius: 8px !important;
  color: white !important;
  font-family: "InterMedium" !important;
  width: 30px !important;
  height: 30px;
  margin: 10px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-event-title {
  margin-left: 6px !important;
}

.fc-daygrid-day-number {
  font-family: "InterMedium" !important;
}

.fc .fc-timegrid-now-indicator-line {
  border-color: var(--theme) !important;
}

.fc-timegrid-now-indicator-line {
  position: relative !important;
}

.rpv-core__page-layer::after {
  box-shadow: none !important;
}

.react-datepicker {
  max-width: max-content !important;
  width: fit-content !important;
  min-width: -webkit-fill-available !important;
}

.css-t3ipsp-control:hover {
  border-color: none !important;
}

.css-t3ipsp-control {
  border-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  border: solid 1px #d0d5dd;
}

.css-nnteb5-control {
  border-radius: 8px !important;
}

.css-t3ipsp-control:focus {
  border-color: none !important;
}

.css-1u9des2-indicatorSeparator {
  background: transparent !important;
}

@media (min-width: 1024px) {
  .react-select__input-container {
    padding: 2.8px !important;
  }
}

@media (min-width: 1536px) {
  .react-select__input-container {
    padding: 5.8px !important;
  }
}

select {
  appearance: none !important;
}

/* Targeting the menu list container within react-select for custom scrollbar */
.react-select__menu-list {
  overflow-y: auto !important;
  max-height: 200px !important;
}

/* Custom scrollbar styles */
.react-select__menu-list::-webkit-scrollbar {
  width: 5px;
}

.react-select__menu-list::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background: var(--theme);
  border-radius: 8px;
}

.react-select__menu-list::-webkit-scrollbar-thumb:hover {
  background: var(--theme-darken);
}

.fc-media-screen {
  height: 843px !important;
}

.swipeable-list {
  height: unset !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.external-costs-chart .highcharts-axis-line {
  stroke: #D0D0D0;
}

/*
////////////////
Multiple Select
///////////////
  */

.react-select-multiple__control {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  font-family: "InterRegular" !important;
  color: #282d46 !important;
  background: #fff !important;
  box-shadow: none !important;
  min-height: 40px !important;
  padding: 0px 5px !important;
}

.react-select-multiple__multi-value {
  background-color: #F5F6F7!important;
  border-radius: 5px !important;
}



.react-select-multipleSecondStyle__control {
  border: 1px solid #F2F4F7 !important;
  border-radius: 8px !important;
  font-family: "InterRegular" !important;
  color: #282d46 !important;
  background: #fff !important;
  box-shadow: none !important;
  min-height: 47px !important;
  padding: 0px 5px !important;
}

.react-select-multipleSecondStyle__multi-value {
  background-color: #F5F6F7!important;
  border-radius: 5px !important;
}

@media (max-width: 1536px) {
 
  .react-select-multipleSecondStyle__control {
    min-height: 42px !important;

  }
}